import React from 'react';
import PropTypes from 'prop-types';

export default function TournamentIcon({ className = '' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            className={className}
        >
            <path d="M11.762 2.732a.25.25 0 0 1 .476 0l.604 1.86a.25.25 0 0 0 .238.172h1.955a.25.25 0 0 1 .147.452L13.6 6.366a.25.25 0 0 0-.09.279l.603 1.86a.25.25 0 0 1-.384.279l-1.582-1.15a.25.25 0 0 0-.294 0l-1.582 1.15a.25.25 0 0 1-.384-.28l.604-1.86a.25.25 0 0 0-.091-.279L8.818 5.216a.25.25 0 0 1 .147-.452h1.955a.25.25 0 0 0 .238-.173l.604-1.86Z" />
            <path
                fillRule="evenodd"
                d="M13 10a1 1 0 1 0-2 0v2H7a1 1 0 0 0-1 1v3H4a1 1 0 0 0-1 1v3a1 1 0 1 0 2 0v-2h4v2a1 1 0 1 0 2 0v-3a1 1 0 0 0-1-1H8v-2h8v2h-2a1 1 0 0 0-1 1v3a1 1 0 1 0 2 0v-2h4v2a1 1 0 1 0 2 0v-3a1 1 0 0 0-1-1h-2v-3a1 1 0 0 0-1-1h-4v-2Z"
                clipRule="evenodd"
            />
        </svg>
    );
}

TournamentIcon.propTypes = {
    className: PropTypes.string
};
