import React from 'react';
import PropTypes from 'prop-types';

export default function SettingsIcon({ className = '' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            className={className}
        >
            <path
                fillRule="evenodd"
                d="M12 5.6a.8.8 0 0 0-.8.8c0 1.354-1.637 2.032-2.594 1.075a.8.8 0 1 0-1.131 1.13c.957.958.279 2.595-1.075 2.595a.8.8 0 0 0 0 1.6c1.354 0 2.032 1.637 1.074 2.594a.8.8 0 0 0 1.132 1.132c.957-.958 2.594-.28 2.594 1.074a.8.8 0 0 0 1.6 0c0-1.354 1.637-2.032 2.594-1.075a.8.8 0 1 0 1.132-1.13c-.958-.958-.28-2.595 1.074-2.595a.8.8 0 0 0 0-1.6c-1.354 0-2.032-1.637-1.075-2.594a.8.8 0 0 0-1.13-1.131c-.958.957-2.595.279-2.595-1.075a.8.8 0 0 0-.8-.8Zm-2.393.622a2.4 2.4 0 0 1 4.786 0 2.4 2.4 0 0 1 3.385 3.385 2.4 2.4 0 0 1 0 4.786 2.4 2.4 0 0 1-3.385 3.385 2.4 2.4 0 0 1-4.786 0 2.4 2.4 0 0 1-3.385-3.385 2.4 2.4 0 0 1 0-4.786 2.4 2.4 0 0 1 3.385-3.385ZM12 8.8a3.2 3.2 0 1 0 0 6.4 3.2 3.2 0 0 0 0-6.4Zm-1.131 2.069a1.6 1.6 0 1 1 2.263 2.263 1.6 1.6 0 0 1-2.263-2.263Z"
                clipRule="evenodd"
            />
        </svg>
    );
}

SettingsIcon.propTypes = {
    className: PropTypes.string
};
