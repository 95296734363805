import React from 'react';
import PropTypes from 'prop-types';

export default function QuizIcon({ className = '' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            className={className}
        >
            <path d="M13.92 14.88c.272 0 .508-.1.708-.3.2-.2.3-.436.3-.708 0-.272-.1-.508-.3-.708-.2-.2-.436-.3-.708-.3-.272 0-.508.1-.708.3-.2.2-.3.436-.3.708 0 .272.1.508.3.708.2.2.436.3.708.3Zm-.72-3.072h1.44c0-.464.048-.804.144-1.02.096-.216.32-.5.672-.852.48-.48.8-.868.96-1.164.16-.296.24-.644.24-1.044 0-.72-.252-1.308-.756-1.764-.504-.456-1.164-.684-1.98-.684-.656 0-1.228.184-1.716.552a2.863 2.863 0 0 0-1.02 1.464l1.296.528c.144-.4.34-.7.588-.9.248-.2.532-.3.852-.3.384 0 .696.108.936.324.24.216.36.508.36.876 0 .224-.064.436-.192.636s-.352.452-.672.756c-.528.464-.852.828-.972 1.092-.12.264-.18.764-.18 1.5ZM8.16 17.76c-.528 0-.98-.188-1.356-.564a1.849 1.849 0 0 1-.564-1.356V4.32c0-.528.188-.98.564-1.356A1.849 1.849 0 0 1 8.16 2.4h11.52c.528 0 .98.188 1.356.564.376.376.564.828.564 1.356v11.52c0 .528-.188.98-.564 1.356a1.849 1.849 0 0 1-1.356.564H8.16Zm0-1.92h11.52V4.32H8.16v11.52ZM4.32 21.6c-.528 0-.98-.188-1.356-.564A1.849 1.849 0 0 1 2.4 19.68V6.24h1.92v13.44h13.44v1.92H4.32Z" />
        </svg>
    );
}

QuizIcon.propTypes = {
    className: PropTypes.string
};
